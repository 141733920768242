.view {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #edf7f6;
    background-image: url('../../assets/bg-focus.jpg');
    background-size: 100%;
    // background-size: calc(var(--vw, 1vw) * 100);

    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0;
    // visibility: hidden;
    @include media('<1100px') {
        background-size: 1100px;
    }

    @include media('<400px') {
        background-size: 750px;
    }

    .intro-background {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        // background-color: #000;
        // background-image: url('../../assets/bg-focus.png'), linear-gradient(0, rgb(31, 27, 27), rgb(27, 24, 24));
        background-size: 100%;
        // background-size: calc(var(--vw, 1vw) * 100);
        background-repeat: no-repeat;
        background-position: 50%;
        width: 100%;
        opacity: 1;
        // z-index: 1;

        @include media('<1100px') {
            background-size: 1100px;
        }
    
        @include media('<400px') {
            background-size: 750px;
        }

        // &-inner {
        //     position: absolute;
        //     position: absolute;
        //     width: 100%;
        //     height: 50vh;
        //     background-color: #000;
        //     z-index: 0;
        // }
    }
}

