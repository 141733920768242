.line-chart {
    position: relative;
    width: 100%;

    .chartLine {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 1s ease-in forwards ;
        animation-delay: 2s;
    }

    @keyframes dash {
        from {
            stroke-dashoffset: 1000;
        }

        to {
            stroke-dashoffset: 0;
        }
    }

    .daysCounter {
        // transform: rotate(90deg);
        font-size: 1.6rem;
        font-weight: 500;
        opacity: 0.7;

        @include media('<tablet'){
            font-size: 2.8rem;
        }
    }
}