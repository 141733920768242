.circle-chart {
    width: 13%;
    margin: 1.0rem auto;
    min-width: 100px;
    max-width: 150px;
    color: #b799ccf3;

    .CircularProgressbar .CircularProgressbar-text {
        font-size: 0.8rem !important;
        letter-spacing: -0.1px;
    }
}