body,
html {
  // visibility: hidden;
  user-select: none;
  overscroll-behavior: none;
  margin: 0;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: $background;
  background-size: 100%;
  font-size: 16px;

  @include media('<tablet') {
    font-size: 14px;
  }

  @include media('<phone') {
    font-size: 13px;
  }
}

body {
  min-width: 300px;
}


* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  color: unset;
  text-decoration: unset;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;

  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }

  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }

  @include media("<=1080px") {
    width: 100%;
    min-width: 100%;
  }

  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;

    @include media("<=phone") {
      padding: 0 16px;
    }
  }

  .v-center {
    align-items: center;
  }

  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;

  .row {
    display: flex;
    height: 100%;
  }
}