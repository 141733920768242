.contact {
    height: 100%;

    .page-body {
        width: 50%;
        margin: 0 auto;
        margin-top: 3rem;

        @include media('<desktop'){
            width: 70%;
        }

        @include media('<tablet'){
            width: 90%;
        }

        .info {
            margin-bottom: 3rem;
            color: darken($color-text, 10%);
            font-weight: 500;
            
            span {}

            .email {
                margin-top: 1rem;
                border: 1px solid rgba($color-gray, 0.5);
                border-radius: 8px;
                padding: 15px;
                line-height: 0;
                display: inline-block;
                user-select: all;

                &::selection {
                    background: rgba($color-blue-form, 0.3);
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;

            .form-group {
                position: relative;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }

            .form-group input {
                height: 1.9rem;
            }

            .form-group textarea {
                resize: none;
            }

            .form-group input~label.control-label,
            .form-group textarea~label.control-label {
                position: absolute;
                top: 0;
                pointer-events: none;
                padding-left: 0.125rem;
                z-index: 1;
                color: #b3b3b3;
                font-size: 1rem;
                font-weight: normal;
                -webkit-transition: all 0.28s ease;
                transition: all 0.28s ease;

            }

            .form-group .bar {
                position: relative;
                border-bottom: 0.0625rem solid #999;
                display: block;
            }

            .form-group .bar::before {
                content: '';
                height: 0.1rem;
                width: 0;
                left: 50%;
                bottom: -0.125rem;
                position: absolute;
                background: $color-blue-form;
                -webkit-transition: left 0.28s ease, width 0.28s ease;
                transition: left 0.28s ease, width 0.28s ease;
                z-index: 3;
            }

            .form-group input,
            .form-group textarea {
                display: block;
                background: none;
                padding: 0.125rem 0.125rem 0.0625rem;
                font-size: 1.2rem;
                border-width: 0;
                border-color: transparent;
                line-height: 1.5rem;
                width: 100%;
                color: transparent;
                -webkit-transition: all 0.28s ease;
                transition: all 0.28s ease;
                box-shadow: none;
            }

            .form-group input:focus,
            .form-group input:valid,
            .form-group input.form-file,
            .form-group input.has-value,
            .form-group textarea:focus,
            .form-group textarea:valid,
            .form-group textarea.form-file,
            .form-group textarea.has-value {
                color: #747b81;
                font-family: inherit;
            }

            .form-group input:focus~.control-label,
            .form-group input:valid~.control-label,
            .form-group input.form-file~.control-label,
            .form-group textarea:focus~.control-label,
            .form-group textarea:valid~.control-label {
                font-size: 0.5rem;
                color: gray;
                top: -1rem;
                left: 0;
            }


            .form-group input:focus,
            .form-group textarea:focus {
                outline: none;
            }

            .form-group input:focus~.control-label,
            .form-group textarea:focus~.control-label {
                color: $color-blue-form;
            }

            .form-group input:focus~.bar::before,
            .form-group textarea:focus~.bar::before {
                width: 100%;
                left: 0;
            }

            .has-error .legend.legend,
            .has-error.form-group .control-label.control-label {
                color: #d9534f;
            }

            .has-error.form-group .form-help,
            .has-error.form-group .helper,
            .has-error.checkbox .form-help,
            .has-error.checkbox .helper,
            .has-error.radio .form-help,
            .has-error.radio .helper,
            .has-error.form-radio .form-help,
            .has-error.form-radio .helper {
                color: #d9534f;
            }

            .has-error .bar::before {
                background: #d9534f;
                left: 0;
                width: 100%;
            }

            .disclaimer span {
                font-size: 0.7rem;
                font-weight: 300;
            }

            .button-container {
                display: flex;
                justify-content: flex-end;
            }

            .button {
                position: relative;
                background: linear-gradient(180deg, rgba($color-gray, 0.1) 0%, rgba($color-gray, 0.5) 100%);
                border: 1px solid $color-gray;
                font-size: 1.8rem;
                color: $color-blue-form;
                margin: 3rem 0;
                padding: 0.75rem 3rem;
                cursor: pointer;
                -webkit-transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
                transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
                overflow: hidden;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            }

            .button span {
                color: darken($color-gray, 10);
                position: relative;
                z-index: 1;
                display: block;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
                font-size: 1rem;
            }

            .button::before {
                content: '';
                position: absolute;
                background: lighten($color-gray, 35);
                border: 50vh solid lighten($color-gray, 30);
                width: 30vh;
                height: 30vh;
                border-radius: 50%;
                display: block;
                top: 50%;
                left: 50%;
                z-index: 0;
                opacity: 1;
                -webkit-transform: translate(-50%, -50%) scale(0);
                transform: translate(-50%, -50%) scale(0);
            }

            .button:hover {
                color: $color-blue-form;
                filter: brightness(95%);
                ;
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
            }

            .button:active::before,
            .button:focus::before {
                -webkit-transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
                transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
                transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
                transition: transform 1.12s ease, opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
                -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
                opacity: 0;
            }

            .button:focus {
                outline: none;
            }

        }
    }

}