.skills {
    &.page {
        height: 100%;
    }

    .page-body {
        padding-top: 2rem;

        .experience-chart {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            width: 80%;
            margin-bottom: 3rem;
            margin-top: 2rem;

            @include media('<1000px'){
                width: 100%;
            }
        }

        .block-charts {
            margin: 3rem 0;
        }

        .circle-charts {
            width: 80%;
            margin: 0 auto;
            &-dev,
            &-design,
            &-eng {
                // margin: 3rem 10%;
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: center;

                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 10px;
                margin-bottom: 3rem;

                @include media('<1000px'){
                    grid-template-columns: 1fr 1fr 1fr;
                }                
                @include media('<560px'){
                    grid-template-columns: 1fr 1fr;
                }
            }

        }
        .section-charts {
            width: 80%;
            margin: 0 auto;
            margin-bottom: 4rem;
            
            @include media('<560px'){
                width: 100%;
            }
        }
    }
}