.home {
    text-align: center;

    h1 {
        margin-bottom: 1.5rem;
        font-weight: 300;
        font-size: 2.5rem;

        @include media('<phone') {
            margin-bottom: 1.5rem;
            font-size: 2rem;
        }
    }

    p {
        text-transform: uppercase;
        letter-spacing: .2rem;
        font-size: .8rem;
        line-height: 2;
        padding: 0 20px;

        @include media('<345px') {
            padding: 0 5px;
        }
    }

    a {
        text-decoration: none;
        transition: filter .6s ease-in-out;
        cursor: pointer;
        color: $color-blue;
        font-weight: 400;

        &:hover {
            filter: hue-rotate(180deg);
        }
    }

    a:nth-of-type(2n) {
        color: $color-orange;
    }

}