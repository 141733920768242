.block-chart {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    span {
        width: 20%;
        min-width: 125px;
        line-height: 1rem;
    }

    &-line {
        height: 10px;
        max-width: 0;
        animation: resize 1s ease-in forwards;
        animation-delay: 2s;
        border-radius: 5px;

        &.developer {
            background-image: linear-gradient(90deg, #b799ccc0, #5eaefdbe);
        }

        &.design {
            background-image: linear-gradient(90deg, #f3eb7daf, #ebaf6a81);
        }

        &.engineering {
            background-image: linear-gradient(90deg, #c0c0c0a8, #7c7c7c9c);
        }

        @include media('<tablet'){
            height: 8px;
        }
    }

    @keyframes resize {
        from {
            max-width: 0;
        }

        to {
            max-width: 100%;
        }
    }
}