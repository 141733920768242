.work {
    height: 100%;

    .page-body-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        // position: relative;

        .item {
            width: 270px;
            height: 270px;
            background-color: #777;
            margin: 1rem;
            // background-image: url();
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center 0;
            position: relative;
            overflow: hidden;
            padding: 1rem;
            display: flex;
            align-items: flex-end;
            opacity: 0;
            cursor: pointer;

            @include media('<1120px') {
                width: 240px;
                height: 240px;
                margin: 0.5rem;
            }

            @include media('<980px') {
                width: 100%;
                height: 240px;
                margin: 1rem;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.7;
                background-image: linear-gradient(0deg, rgba(#fff, 1) 0, rgba(#fff, 0.8) 20%, rgba(#fff, 0) 30%, rgba(#fff, 0) 100%);
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.4;
                background-image: linear-gradient(-40deg, rgba($color-gradient1, 1), rgba($color-gradient2, 0.6));
                transition: opacity 0.6s ease;
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }




            &-title {
                text-align: left;
                color: darken($color-text, 10%);
                font-weight: 500;
                line-height: 1.2rem;
                font-size: 0.9rem;
                z-index: 1;
                // transform: translateX(-300px);

            }

            &-content {
                display: none;
            }

        }
    }

    .page-body-modal {
        .modal {
            display: none;
            position: fixed;
            background-image: linear-gradient(-40deg, rgba($color-gradient1, 1), rgba($color-gradient2, 0.6));
            max-width: 70vmin;
            max-height: 70vmin;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
            height: 0;
            width: 0;
            border: 1px solid #999;
            z-index: 10;
            box-shadow: 4px 4px 8px 1px #555;

            @include media('<1000px') {
                max-width: 60vmin;
                max-height: 60vmin;
            }

            @include media('<tablet') {
                max-width: 80vmin;
                max-height: 80vmin;
            }

            &-item {
                opacity: 0;
                height: 100%;

                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center 0;
                    height: 100%;
                    color: darken($color-text, 20);
                    font-size: 1rem;

                    @include media('<tablet') {
                        font-size: 0.9rem;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0.9;
                        background-image: radial-gradient(circle, rgba(0, 0, 0, 0) 30%, rgba($color-gradient1, 0.2) 70%, rgba(0, 0, 0, 0.35) 100%), linear-gradient(180deg, rgba($color-gradient1, 0.6), rgba($color-gradient2, 0.8));
                        transition: opacity 0.6s ease;
                    }

                    &-title {
                        font-size: 1.3em;
                        font-weight: 400;
                        text-align: center;
                        width: 75%;
                        padding: 2rem;
                        z-index: 1;
                        display: none;
                    }

                    &-content {
                        padding: 0 1rem;
                        width: 100%;
                        position: absolute;
                        height: 100%;
                        display: flex;
                        padding: 1rem;
                        color: darken($color-text, 10%);
                        font-weight: 500;


                        .close-btn {
                            position: absolute;
                            opacity: 0.7;
                            right: 1rem;
                            width: 30px;
                            cursor: pointer;
                        }

                        .item-description {
                            position: absolute;
                            bottom: 18%;
                            margin-left: calc(1.5rem + 10px);
                            font-size: 1.2em;
                            display: flex;
                            flex-direction: column;
                            color: darken($color-orange, 10%);
                            text-shadow: 1px 1px 1px rgb(37, 37, 37);
                        }

                        .item-links {
                            position: absolute;
                            right: calc(1.5rem + 10px);
                            bottom: 18%;
                            font-size: 1.4em;
                            display: flex;
                            flex-direction: column;
                            color: darken($color-orange, 10%);
                            text-shadow: 1px 1px 1px rgb(37, 37, 37);

                            a {
                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            span {
                                margin-top: 5px;
                            }
                        }

                        .stars {
                            align-self: flex-end;
                            margin: 0 auto;

                            svg {
                                width: 30px;
                                height: 30px;
                                margin: 0 3px;
                                

                                @include media('<tablet') {
                                    width: 20px;
                                    height: 20px;
                                }
                            }



                            .filled {
                                path {
                                    fill: #FAFFC0;
                                }
                            }
                        }

                    }

                }
            }
        }
    }


}