.main {
    width: 70vw;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90vh;
    height: 1000px;
    border: 1px solid #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);

    @include media('<tablet') {
        width: 95%;
        min-width: 300px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        z-index: 0;
        display: block;
        height: 100%;
        width: 100%;
        opacity: 1;
        background-image: linear-gradient(0, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(-20deg, rgba($color-gradient1, 0.3), rgba($color-gradient2, 0.3)), url('../../assets/bg-focus.jpg');
        background-size: 100vw, 100vw, 100vw;
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-position: center center, center center, center center;
        filter: blur(15px);

        @include media('<1100px') {
            background-size: 1100px;
        }
    
        @include media('<400px') {
            background-size: 750px;
        }
    }

    .nav-container {
        position: absolute;
        height: 45px;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-self: flex-end;
        justify-content: center;
        background-image: linear-gradient(180deg, rgba($color-gray, 0.1) 0%, rgba($color-gray, 0.5) 100%);
        opacity: 0;
    }

    .page {
        position: absolute;
        width: 90%;
        border-radius: .125rem;
        padding: 2rem 3rem;
        overflow: auto;
        
        @include media('<tablet') {
            padding: 1rem 1rem;
        }

        @include media('<phone') {
            width: 99%;
            padding: 1rem;
        }

        .simplebar-scrollbar:before {
            background: rgb(185, 197, 223);
        }
    }

    .page:not(.home) {
        position: absolute;
        width: 90%;
        // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .13), 0 2px 10px 0 rgba(0, 0, 0, .10);
        border-radius: .125rem;
        // background-color: #fff;
        padding: 2rem 3rem;
        min-height: 40vh;
        overflow: auto;

        @include media('<phone') {
            width: 90%;
            padding: 1rem;
        }

        .page {
            &-content {
                color: $color-text;
            }

            &-header {
                padding-bottom: 1rem;
                text-align: center;
                border-bottom: 1px solid #e9ecef;
                margin-bottom: 1rem;

                h2 {
                    font-size: 2rem;
                    margin: 0;
                    font-weight: 400;
                    line-height: 1.2;
                    color: $color-gray;
                    text-transform: uppercase;

                    @include media('<phone') {
                        // font-size: 1.6rem;
                    }
                }

            }

            &-body {
                line-height: 1.6rem;
                font-size: 0.8rem;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: .2rem;
                padding-bottom: 2rem;

                @include media('<phone') {
                    // font-size: 0.6rem;
                    line-height: 1.2rem;
                }

                p {
                    text-align: justify;
                }
            }

        }


    }

    .page.about {
        .page-body {
            width: 70%;
            margin: 0 auto;

            @include media('<1000px') {
                width: 100%;
            }
        }
    }

    .page-enter {
        opacity: 0;
    }

    .page-enter-active {
        opacity: 1;
        transition: opacity 400ms;
        transition-delay: 1000ms;
    }

    .page-exit {
        opacity: 1;
    }

    .page-exit-active {
        opacity: 0;
        transition: opacity 400ms;
    }
}