.nav {
    margin-bottom: 0;
    padding-left: 0;
    border: solid 1px #ffffff;
    border-radius: 0px;
    position: absolute;
    display: flex;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    z-index: 1;
    align-self: center;
    height: calc(100% - 4px);
    // flex-wrap: wrap;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 100%;
        opacity: 0.7;
        background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
        z-index: 0;
    }

    a {
        border-left: solid 1px #ffffff;
        border-radius: 0px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        line-height: 100%;
        padding: 0 1.6rem 0 1.6rem;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-size: 0.8rem;
        border-bottom: 0;
        cursor: pointer;
        overflow: hidden;
        user-select: none;
        color: $color-gray;
        background-color: transparent;
        font-weight: 600;
        transition: all .4s ease;
        position: relative;

        @include media('<830px') {
            padding: 0 5px;
            // font-size: 0.6rem;
        }

        @include media('<phone') {
            padding: 0 5px;
            font-size: 0.7rem;
        }

        &:hover {
            background-color: rgba(223, 155, 155, 0.2);
        }

        &:active {
            background-color: rgba(223, 155, 155, 0.747);
        }
    }
}