.socials {
    text-align: center;
    margin: 20px 0;

    .social {
        margin: 0 10px;
        svg {
            width: 30px;
            @include media('<phone'){
                width: 20px;
            }
        }  
    } 
}